import React from 'react';
import { Layout } from 'components';
import './form-submit-confirmation.scss';

const FormSubmitConfirmation = () => (
  <Layout>
    <section className="thankyou-page">
      <div className="wrapper">
        <h1 className="thankyou-page-title">Thanks for briefing us.</h1>
        <h3 className="thankyou-page-subtitle">We’ll be in touch very soon.</h3>
        <p className="thankyou-page-content">
          In the meantime have a look at the projects we’ve done in the past on our showreel or flick through some of
          our articles on sustainability or consumer psychology over at Lemmy Said
        </p>
      </div>
    </section>
  </Layout>
);

export default FormSubmitConfirmation;
